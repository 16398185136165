import { admin2 } from '@/api/instance'

export const UploadFile = async ({
  file, type, name,
}) => {
  const form = new FormData()
  form.append('file', file)
  form.append('type', type)
  form.append('name', name)
  return await admin2({
    method: 'POST',
    url: '/file/upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: form,
  })
}

export const UploaduserRestrictedFileFile = async ({ shopId, file, name, memberId }) => {
  const form = new FormData()
  form.append('file', file)
  form.append('name', name)
  // form.append('memberId', memberId)
  return await admin2({
    method: 'POST',
    url: `/${shopId}/userRestrictedFile/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: form,
  })
}

export const GetRestrictedFileUrl = async ({ shopId, memberId, userRestrictedFileId }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/userRestrictedFile/getDownloadUrl`,
    data: {
      memberId,
      userRestrictedFileId,
    },
  })
}
